import { Image } from '@graphcommerce/image'
import {
  Footer as FooterBase,
  FooterProps as FooterBaseProps,
  withTheme,
} from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, IconButton, Link } from '@mui/material'
import { lightTheme } from '../theme'
import { FooterQueryFragment } from './FooterQueryFragment.gql'

export type FooterProps = FooterQueryFragment & Pick<FooterBaseProps, 'sx'>

function FooterUnthemed(props: FooterProps) {
  const { footer, sx = [] } = props

  return (
    <FooterBase
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      socialLinks={footer?.socialLinks?.map((link) => (
        <IconButton
          href={link.url}
          key={link.url}
          aria-label={link.title}
          color='inherit'
          size='medium'
          target='_blank'
        >
          {link.asset ? (
            <Image
              layout='fill'
              src={link.asset.url}
              width={24}
              height={24}
              unoptimized
              alt={link.asset.alt || link.title}
              sx={(theme) => ({
                filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'invert(0%)',
              })}
            />
          ) : (
            link.title
          )}
        </IconButton>
      ))}
      customerService={
        <Button href='/service' variant='pill' color='inherit'>
          <Trans id='Customer Service' />
        </Button>
      }
      copyright={
        <>
          <span>{footer?.copyright}</span>
          {footer?.legalLinks?.map((link) => (
            <Link key={link.title} href={link.url} color='textPrimary' underline='always'>
              {link.title}
            </Link>
          ))}
        </>
      }
    />
  )
}

export const Footer = withTheme(FooterUnthemed, lightTheme)
