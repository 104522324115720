import { Logo as LogoBase } from '@graphcommerce/next-ui'
import { useTheme } from '@mui/material'
import svgLogoDark from './verdouw_dark.svg'
import svgLogoLight from './verdouw_light.svg'

export function Logo() {
  const inverted = useTheme().palette.mode === 'dark'

  return (
    <LogoBase
      sx={{
        '& .GcLogo-logo': {
          width: { xs: '93px', md: '170px' },
          marginTop: { xs: 0, md: '-4px' },
        },
      }}
      image={{
        alt: 'Verdouw',
        src: inverted ? svgLogoDark : svgLogoLight,
        unoptimized: true,
      }}
    />
  )
}
