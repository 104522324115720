import { PageOptions } from '@graphcommerce/framer-next-pages'
import { HygraphPagesQuery, hygraphPageContent } from '@graphcommerce/graphcms-ui'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { PageMeta, MetaRobots, withTheme, GetStaticProps } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import {
  RowRenderer,
  RowProduct,
  LayoutNavigation,
  LayoutNavigationProps,
  LayoutDocument,
} from '../components'
import { RowHeroBanner } from '../components/GraphCMS/RowHeroBanner/RowHeroBanner'
import { RowPageList } from '../components/GraphCMS/RowPageList'
import { RowSpecialBanner } from '../components/GraphCMS/RowSpecialBanner/RowSpecialBanner'
import { darkTheme, lightTheme } from '../components/theme'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & {
  latestList: ProductListQuery
  favoritesList: ProductListQuery
  swipableList: ProductListQuery
}
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPageHome(props: Props) {
  const { pages, latestList, favoritesList, swipableList } = props
  const page = pages?.[0]
  const title = page?.title ?? ''
  const latest = latestList?.products?.items?.[0]
  const favorite = favoritesList?.products?.items?.[0]
  const swipable = swipableList?.products?.items?.[0]
  const metaRobots = page?.metaRobots.toLowerCase().split('_').flat(1) as MetaRobots[]

  return (
    <Box sx={{ background: lightTheme.palette.background.default }}>
      <PageMeta
        title={page?.metaTitle ?? title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={metaRobots}
        canonical='/'
      />

      {pages?.[0] && (
        <RowRenderer
          content={pages?.[0].content}
          renderer={{
            RowHeroBanner: withTheme(RowHeroBanner, darkTheme),
            RowPageList: withTheme(RowPageList, lightTheme),
            RowProduct: withTheme((rowProps) => {
              const { identity } = rowProps

              if (identity === 'home-favorites')
                return (
                  <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
                )
              if (identity === 'home-latest')
                return <RowProduct {...rowProps} {...latest} items={latestList.products?.items} />
              if (identity === 'home-swipable')
                return (
                  <RowProduct {...rowProps} {...swipable} items={swipableList.products?.items} />
                )
              return (
                <RowProduct {...rowProps} {...favorite} items={favoritesList.products?.items} />
              )
            }, darkTheme),
            RowSpecialBanner: withTheme(
              (rowProps) => <RowSpecialBanner {...rowProps} {...latest} />,
              lightTheme,
            ),
          }}
        />
      )}
    </Box>
  )
}

CmsPageHome.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPageHome

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const conf = client.query({ query: StoreConfigDocument })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  const favoritesList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTIx' } } },
  })

  const latestList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTAy' } } },
  })

  const swipableList = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'MTIy' } } },
  })

  const page = hygraphPageContent(staticClient, 'page/home')
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  if (!(await page).data.pages?.[0]) return { notFound: true }

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      latestList: (await latestList).data,
      favoritesList: (await favoritesList).data,
      swipableList: (await swipableList).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
