import { SearchLinkProps } from '@graphcommerce/magento-search'
import { iconSearch, IconSvg, extendableComponent } from '@graphcommerce/next-ui'
import { Box, Link } from '@mui/material'
import { useRouter } from 'next/router'
import { KeyboardEventHandler, MouseEventHandler } from 'react'

const name = 'SearchLink' as const
const parts = ['root', 'text', 'svg'] as const
const { classes } = extendableComponent(name, parts)

export function SearchLink(props: SearchLinkProps) {
  const { href, sx = [], children, breakpoint, onClick, ...linkProps } = props
  const router = useRouter()

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    onClick?.(e)
    return router.push(href)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      return router.push(href)
    }
    return undefined
  }

  return (
    <Link
      href={href}
      className={classes.root}
      underline='none'
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      sx={[
        (theme) => ({
          justifySelf: 'center',
          width: '100%',
          borderRadius: 100,
          display: breakpoint ? { xs: 'none', [breakpoint]: 'flex' } : 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'space-between', md: 'end', lg: 'space-between' },
          gap: theme.spacings.xs,
          color: theme.palette.text.secondary,
          border: `1px solid ${theme.palette.text.secondary}`,
          py: '8px',
          px: theme.spacings.xxs,
          '&:hover': {
            borderColor: theme.palette.text.primary,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...linkProps}
    >
      <Box
        sx={{ display: { xs: 'unset', md: 'none', lg: 'usnet' }, typography: 'body2' }}
        className={classes.text}
      >
        {children ?? <>&nbsp;</>}
      </Box>
      <IconSvg
        src={iconSearch}
        className={classes.svg}
        sx={{ color: 'text.primary' }}
        size='large'
      />
    </Link>
  )
}
