import { CartFab } from '@graphcommerce/magento-cart'
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import { CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefault,
  LayoutDefaultProps,
  iconHeart,
  MenuFabSecondaryItem,
  IconSvg,
  DesktopNavItem,
  iconChevronDown,
  NavigationProvider,
  NavigationOverlay,
  Button,
  useNavigationSelection,
  useMemoDeep,
  NavigationFab,
} from '@graphcommerce/next-ui'
import iconShoppingCart from '@graphcommerce/next-ui/icons/cart.svg'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Divider, Fab, ThemeProvider, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { Suspense } from 'react'
import { CustomerFab } from '../MagentoCustomer/CustomerFab/CustomerFab'
import { SearchLink } from '../Search/SearchLink'
import { darkTheme, lightTheme } from '../theme'
import { Footer } from './Footer'
import { LayoutQuery } from './Layout.gql'
import { useHomeScrollTheme } from './LayoutFull'
import { Logo } from './Logo'
import { TopMenu } from './TopMenu'
import iconCalculator from './calculator.svg'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const { footer, menu, children, mainMenu, topMenu, ...uiProps } = props
  const selection = useNavigationSelection()
  const router = useRouter()

  const theme = router.pathname === '/' ? darkTheme : lightTheme
  const scrollTheme = useHomeScrollTheme()

  const borderAnimation = {
    display: 'flex',
    flexDirection: 'column',
    jusifyContent: 'center',
    '&:after': {
      marginTop: '-2px',
      display: 'block',
      content: '""',
      borderBottom: `solid 2px ${theme.palette.primary.main}`,
      transform: 'scaleX(0)',
      transition: 'transform 125ms ease-in-out',
      transformOrigin: '100% 50%',
    },
    '&:hover:after': { transform: 'scaleX(1)', transformOrigin: '0% 50%' },
  }

  return (
    <ThemeProvider theme={theme}>
      <NavigationProvider
        serverRenderDepth={4}
        selection={selection}
        items={useMemoDeep(
          () => [
            <SearchLink
              href='/search'
              onClick={() => selection.set(false)}
              sx={(_theme) => ({
                width: `calc(100% - ${_theme.spacing(4)})`,
                m: 2,
                mb: _theme.spacings.xs,
              })}
              aria-label={i18n._(/* i18n */ 'Search...')}
            >
              <Trans id='Search...' />
            </SearchLink>,
            { id: 'home', name: 'Home', href: '/' },
            ...magentoMenuToNavigation(menu, true),
            ...(mainMenu?.pageLinks.map((page) => ({
              id: page.url,
              name: page.title,
              href: page.url,
            })) ?? []),
            <Divider sx={(_theme) => ({ my: _theme.spacings.xs })} />,
            ...(topMenu?.pageLinks.map((page) => ({
              id: page.url,
              name: page.title,
              href: page.url,
            })) ?? []),
            <Divider sx={(_theme) => ({ my: _theme.spacings.xs })} />,
            <CustomerMenuFabItem
              onClick={() => selection.set(false)}
              key='account'
              guestHref='/account/signin'
              authHref='/account'
            >
              <Trans id='Account' />
            </CustomerMenuFabItem>,
            <WishlistMenuFabItem
              key='wishlist'
              icon={<IconSvg src={iconHeart} size='medium' />}
              onClick={() => selection.set(false)}
            >
              <Trans id='Wishlist' />
            </WishlistMenuFabItem>,
            <MenuFabSecondaryItem
              key='Calculation'
              icon={<IconSvg src={iconCalculator} size='medium' />}
              href='/calculations'
              onClick={() => selection.set(false)}
            >
              <Trans id='Calculations' />
            </MenuFabSecondaryItem>,
          ],
          [mainMenu?.pageLinks, menu, selection, topMenu?.pageLinks],
        )}
      >
        <ThemeProvider theme={lightTheme}>
          <NavigationOverlay
            stretchColumns={false}
            variantSm='left'
            sizeSm='full'
            itemWidthSm='70vw'
            justifySm='start'
            variantMd='left'
            sizeMd='full'
            justifyMd='start'
            itemWidthMd='230px'
            mouseEvent='hover'
            itemPadding='md'
            sx={{
              '& > ul > li > a, & > ul > li > [role=button]': {
                '& span': {
                  typography: 'body1',
                },
              },
            }}
          />
        </ThemeProvider>
      </NavigationProvider>

      <LayoutDefault
        sx={{
          gridTemplateRows: 'auto auto auto 1fr auto',
          '& .LayoutDefault-header': {
            typography: 'body1',
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            position: 'sticky',
            [theme.breakpoints.up('md')]: {
              display: 'grid',
              gridAutoGlow: 'column',
              gridTemplateColumns: 'auto auto 1fr auto',
            },
          },
          '& .LayoutDefault-fabs': {
            zIndex: 'appBar',
          },
        }}
        {...uiProps}
        noSticky={router.asPath.split('?')[0] === '/'}
        beforeHeader={
          <TopMenu>
            {topMenu?.pageLinks.map((item) => (
              <DesktopNavItem
                key={item.url}
                href={item.url}
                sx={{
                  typography: 'body2',
                  pt: '3px',
                  '& .DesktopNavItem-line': { mt: '3px' },
                  ...borderAnimation,
                }}
              >
                {item.title}
              </DesktopNavItem>
            ))}
          </TopMenu>
        }
        header={
          <>
            <Logo />
            <DesktopNavBar
              sx={{
                '& .DesktopNavBar-right, .DesktopNavBar-left': {
                  ' > button': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <Button
                variant='contained'
                size='small'
                onClick={() => selection.set([menu?.items?.[0]?.uid || ''])}
                sx={{ boxShadow: 'none' }}
              >
                <Typography component='span' variant='h6' sx={{ marginRight: 1 }}>
                  {menu?.items?.[0]?.name}
                </Typography>

                <IconSvg src={iconChevronDown} />
              </Button>
              {mainMenu?.pageLinks.map((item) => (
                <DesktopNavItem
                  key={item?.url}
                  href={item?.url}
                  sx={{
                    typography: 'subtitle1',
                    ...borderAnimation,
                  }}
                >
                  {item.title}
                </DesktopNavItem>
              ))}
            </DesktopNavBar>
            {!router.pathname.startsWith('/search') && (
              <SearchLink
                aria-label={i18n._(/* i18n */ 'Search...')}
                href='/search'
                breakpoint='md'
                sx={{
                  mr: theme.spacings.xxs,
                  width: '80%',
                  justifySelf: 'flex-end',
                  maxWidth: '500px',
                }}
              >
                <Trans id='Search...' />
              </SearchLink>
            )}
            <DesktopNavActions
              sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}
            >
              <Fab
                href='/calculations'
                aria-label={i18n._(/* i18n */ `Calculations`)}
                size='large'
                color='inherit'
              >
                <IconSvg src={iconCalculator} size='large' />
              </Fab>
              <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
              <CustomerFab
                guestHref='/account/signin'
                authHref='/account'
                sx={{ color: 'text.primary' }}
              />
              <CartFab
                sx={{ '& .CartFab-shadow': { display: 'none' } }}
                icon={<IconSvg src={iconShoppingCart} size='large' />}
              />
            </DesktopNavActions>
          </>
        }
        footer={<Footer footer={footer} />}
        cartFab={
          <ThemeProvider theme={scrollTheme}>
            <CartFab
              icon={<IconSvg src={iconShoppingCart} size='large' />}
              sx={{ color: scrollTheme.palette.text.primary, display: { md: 'none' } }}
            />
          </ThemeProvider>
        }
        menuFab={
          <ThemeProvider theme={scrollTheme}>
            <NavigationFab onClick={() => selection.set([])} sx={{ display: { md: 'none' } }} />
          </ThemeProvider>
        }
      >
        {children}
      </LayoutDefault>
    </ThemeProvider>
  )
}
