import { useMotionValueValue, clientSize } from '@graphcommerce/framer-utils'
import { CartFab } from '@graphcommerce/magento-cart'
import { CustomerFab, CustomerMenuFabItem } from '@graphcommerce/magento-customer'
import { SearchLink } from '@graphcommerce/magento-search'
import { WishlistFab, WishlistMenuFabItem } from '@graphcommerce/magento-wishlist'
import {
  DesktopNavActions,
  DesktopNavBar,
  LayoutDefault,
  LayoutDefaultProps,
  iconCustomerService,
  iconHeart,
  MenuFab,
  MenuFabSecondaryItem,
  PlaceholderFab,
  IconSvg,
  DesktopNavItem,
  MenuFabItem,
  iconChevronRight,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Button, Fab, ListItem, ListItemIcon, ThemeProvider, useTheme } from '@mui/material'
import { useMotionValue, useScroll, useTransform } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { DefaultPageQuery } from '../../graphql/DefaultPage.gql'
import { PageLayoutQuery } from '../../graphql/PageLayout.gql'
import { ContactImage } from '../ContactLink/ContactImage'
import { ITEM_SLIDER_ID } from '../GraphCMS/RowProduct/variant/ItemSlider'
import { darkTheme, lightTheme } from '../theme'
import { Footer } from './Footer'
import { Logo } from './Logo'

export type LayoutFullProps = PageLayoutQuery &
  Omit<
    DefaultPageQuery & Omit<LayoutDefaultProps, 'footer'>,
    'pages' | 'header' | 'cartFab' | 'menuFab'
  >

export function useHomeScrollTheme() {
  const router = useRouter()
  const { scrollY } = useScroll()
  const switchPoint = useMotionValue(1000000)
  const mdValue = useTheme().breakpoints.values.md

  useEffect(() => {
    const el = document.getElementById(ITEM_SLIDER_ID)
    if (!el) return () => {}
    const ro = new ResizeObserver(([entry]) => {
      switchPoint.set(el.offsetTop + entry.contentRect.height)
    })
    ro.observe(el)
    return () => ro.disconnect()
  }, [switchPoint])

  const changed = useTransform(
    [scrollY, switchPoint, clientSize.x, clientSize.y],
    ([y, point, cx, cy]: number[]) => {
      if (cx > mdValue) return y >= point
      return y >= point - cy + 56
    },
  )
  const isChanged = useMotionValueValue(changed, (c) => c)

  if (router.pathname === '/') return isChanged ? lightTheme : darkTheme
  return lightTheme
}

export function LayoutFull(props: LayoutFullProps) {
  const { footer, children, mainMenu, contactLink, ...uiProps } = props

  const router = useRouter()
  // const onSearchStart = useCallback(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-floating-promises
  //   router.push('/search')
  // }, [router])

  const scrollTheme = useHomeScrollTheme()

  const theme = router.pathname === '/' ? darkTheme : lightTheme

  const underlineAnimation = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingY: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '64px',
      transform: 'scaleX(0)',
      height: '2px',
      bottom: '4px',
      backgroundColor: 'primary.main',
      transformOrigin: 'bottom right',
      transition: 'transform 0.15s ease-out',
    },
    '&:hover': {
      '&:after': {
        transform: 'scaleX(1)',
        transformOrigin: 'bottom left',
      },
    },
  }

  return (
    <LayoutDefault
      sx={{
        '& .LayoutDefault-header': {
          typography: 'body1',
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
        },
        '& .LayoutDefault-fabs': {
          zIndex: 'appBar',
        },
      }}
      {...uiProps}
      noSticky={router.asPath.split('?')[0] === '/'}
      header={
        <ThemeProvider theme={theme}>
          <Logo />
          <DesktopNavBar>
            <Button
              href='/producten'
              variant='contained'
              size='small'
              sx={{ typography: 'subtitle1', ...underlineAnimation }}
            >
              Producten
            </Button>

            {mainMenu?.pageLinks.map((item) => (
              <DesktopNavItem
                key={item.url}
                href={item.url}
                sx={{
                  typography: 'subtitle1',
                  ...underlineAnimation,
                }}
              >
                {item.title}
              </DesktopNavItem>
            ))}
          </DesktopNavBar>
          <DesktopNavActions>
            {contactLink && (
              <Link href={contactLink.url}>
                <ContactImage asset={contactLink.asset} sx={{ marginRight: theme.spacings.xs }} />
              </Link>
            )}
            {!router.pathname.startsWith('/search') && (
              <SearchLink href='/search' aria-label={i18n._(/* i18n */ 'Search...')} />
            )}
            <Fab
              href='/service'
              aria-label={i18n._(/* i18n*/ `Customer Service`)}
              size='large'
              color='inherit'
            >
              <IconSvg src={iconCustomerService} size='large' />
            </Fab>
            <WishlistFab icon={<IconSvg src={iconHeart} size='large' />} />
            <CustomerFab guestHref='/account/signin' authHref='/account' />
            <PlaceholderFab />
          </DesktopNavActions>
        </ThemeProvider>
      }
      footer={<Footer footer={footer} />}
      cartFab={
        <ThemeProvider theme={scrollTheme}>
          <CartFab />
        </ThemeProvider>
      }
      menuFab={
        <ThemeProvider theme={scrollTheme}>
          <MenuFab
            search={
              <SearchLink
                href='/search'
                sx={{ width: '100%' }}
                aria-label={i18n._(/* i18n */ 'Search...')}
              >
                <Trans id='Search...' />
              </SearchLink>
            }
            secondary={[
              <CustomerMenuFabItem key='account' guestHref='/account/signin' authHref='/account'>
                <Trans id='Account' />
              </CustomerMenuFabItem>,
              <MenuFabSecondaryItem
                key='service'
                icon={<IconSvg src={iconCustomerService} size='medium' />}
                href='/service'
              >
                <Trans id='Customer Service' />
              </MenuFabSecondaryItem>,
              contactLink && (
                <MenuFabSecondaryItem
                  key='contact'
                  icon={<ContactImage size='small' {...contactLink} />}
                  href={contactLink.url ?? ''}
                >
                  <Trans id='Contact' />
                </MenuFabSecondaryItem>
              ),
              <WishlistMenuFabItem key='wishlist' icon={<IconSvg src={iconHeart} size='medium' />}>
                <Trans id='Wishlist' />
              </WishlistMenuFabItem>,
            ]}
          >
            <MenuFabItem href='/' sx={{ typography: 'body1' }}>
              <Trans id='Home' />
            </MenuFabItem>
            <ListItem
              disableGutters
              disablePadding
              secondaryAction={
                <ListItemIcon sx={{ minWidth: 'unset', py: 1, color: 'primary.main' }}>
                  <IconSvg src={iconChevronRight} size='large' />
                </ListItemIcon>
              }
            >
              <MenuFabItem href='/producten' sx={{ typography: 'body1' }}>
                Producten
              </MenuFabItem>
            </ListItem>

            {mainMenu?.pageLinks.map((item) => (
              <MenuFabItem key={item.url} href={item.url} sx={{ typography: 'body1' }}>
                {item.title}
              </MenuFabItem>
            ))}
          </MenuFab>
        </ThemeProvider>
      }
    >
      {children}
    </LayoutDefault>
  )
}
