import { Box, Container } from '@mui/material'

export type TopMenuProps = {
  children?: React.ReactNode
}

export function TopMenu(props: TopMenuProps) {
  const { children } = props

  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'flex-end',
        top: 0,
        zIndex: theme.zIndex.appBar,
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacings.lg,
          padding: `0 ${theme.spacings.md}`,
          background: theme.palette.divider,
          borderBottomLeftRadius: '32px',
          borderBottomRightRadius: '32px',

          '& > .MuiLink-root': {
            lineHeight: 'normal',
            paddingTop: 1,

            '& .DesktopNavItem-line': {
              marginTop: 1,
            },
          },
        })}
      >
        {children}
      </Box>
    </Container>
  )
}
